<div class="drawb" tabindex="1">

    <!-- Drawing Settings -->
    <div class="drawb-left">
        <div class="drawb-settings">
            <div class="drawb-top" [class.Urgency-Warning]="drawingUrgency==DrawingUrgencies.Warning" [class.Urgency-Alert]="drawingUrgency==DrawingUrgencies.Alert">
                <div class="drawb-setting" *ngIf="showBrushSizeSelector">
                    <div id="size-controller">
                        <input type="range" min="2" max="40" [value]="selectedBrushSize" [(ngModel)]="selectedBrushSize">
                        <div id="preview-size-wrapper">
                            <div id="preview-size" [ngStyle]="{'height': selectedBrushSize * 1.3 + 'px', 'width': selectedBrushSize * 1.3 + 'px', 'backgroundColor': selectedColor}"></div>
                        </div>
                    </div>
                </div>
                <div class="drawb-setting"></div>
                <div class="drawb-setting-color" *ngIf="showColorSelector && ((!drawingOptions.colorList) || (drawingOptions.colorList.length==0))">
                    <div class='drawb-colors'>
                        <div class="color-input" matTooltip="Color" (click)='openColorPicker($event)' [ngStyle]="{'background-color': selectedColor}">
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showColorSelector && drawingOptions.colorList && drawingOptions.colorList.length > 1">
                <mat-radio-group class="color-picker-group" [(ngModel)]="selectedColor" (change)="switchToDrawIfErase()">
                    <div class="color-picker-dot" *ngFor="let colorChoice of drawingOptions.colorList; let i = index" [ngStyle]="{'background-color': colorChoice}"   (click)="handleColorChange(colorChoice)">
                        <mat-radio-button [className]="'color-picker-rb'" [value]="colorChoice" [checked]="i==0"></mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <!-- Drawing Canvas -->
        <div class="drawb-canvas">
            <canvas appDrawing class="draw-canvas" [width]="drawingWidth" [height]="drawingHeight" [ngStyle]="{'background-image': 'url(' + drawingOptions.canvasBackground + ')'}" [drawingOptions]="drawingOptions" [lineColor]="selectedColor" [lineWidth]="selectedBrushSize"
                [drawingMode]="drawingMode" (drawingEmitter)="onDrawingChange($event)" [galleryRecentDrawing]="this.galleryRecentDrawing">
            </canvas>
        </div>
    </div>

    <div class="drawb-right" [class.Urgency-Warning]="drawingUrgency==DrawingUrgencies.Warning" [class.Urgency-Alert]="drawingUrgency==DrawingUrgencies.Alert">
        <div class="drawb-tools" *ngIf="showEraser">
            <div class="drawb-tools-section">
                <scrawlbrawl-iconbutton matTooltip="Brush" matTooltipPosition="right" [mdTooltipClass]="{'tooltip': true}" (click)="drawingMode=drawingModes.Draw" [active]="drawingMode==drawingModes.Draw">
                    <ion-icon name="brush-outline"></ion-icon>
                </scrawlbrawl-iconbutton>
                <scrawlbrawl-iconbutton matTooltip="Erase" matTooltipPosition="right" [mdTooltipClass]="{'tooltip': true}" (click)="drawingMode=drawingModes.Erase" [active]="drawingMode==drawingModes.Erase">
                    <eraser-svg></eraser-svg>
                </scrawlbrawl-iconbutton>
                <scrawlbrawl-iconbutton matTooltip="Fill" matTooltipPosition="right" [mdTooltipClass]="{'tooltip': true}" (click)="drawingMode=drawingModes.FloodFill" [active]="drawingMode==drawingModes.FloodFill">
                    <ion-icon name="color-fill-outline"></ion-icon>
                </scrawlbrawl-iconbutton>
            </div>
            <div class="drawb-tools-section">
                <scrawlbrawl-iconbutton matTooltip="Undo" matTooltipPosition="right" [mdTooltipClass]="{'tooltip': true}" (click)="drawingDirective.onPerformUndo()">
                    <ion-icon name="arrow-undo-outline"></ion-icon>
                </scrawlbrawl-iconbutton>
                <scrawlbrawl-iconbutton matTooltip="Redo" matTooltipPosition="right" [mdTooltipClass]="{'tooltip': true}" (click)="drawingDirective.onPerformRedo()">
                    <ion-icon name="arrow-redo-outline"></ion-icon>
                </scrawlbrawl-iconbutton>
                <scrawlbrawl-iconbutton matTooltip="Clear" matTooltipPosition="right" [mdTooltipClass]="{'tooltip': true}" (click)="drawingDirective.clearDrawing()">
                    <ion-icon [style.color]="'var(--red-secondary)'" name="trash-outline"></ion-icon>
                </scrawlbrawl-iconbutton>
            </div>
            <div class="drawb-tools-section">
                <scrawlbrawl-iconbutton matTooltip="Gallery" matTooltipPosition="right" [mdTooltipClass]="{'tooltip': true}" *ngIf="drawingOptions.galleryOptions" (click)="openGallery($event)">
                    <ion-icon name="images-outline"></ion-icon>
                </scrawlbrawl-iconbutton>
                <scrawlbrawl-iconbutton matTooltip="Favorite" matTooltipPosition="right" [mdTooltipClass]="{'tooltip': true}" *ngIf="drawingOptions.galleryOptions" (click)="this.saveToFavorites()">
                    <ion-icon name="star-outline"></ion-icon>
                </scrawlbrawl-iconbutton>
            </div>
        </div>
    </div>
</div>