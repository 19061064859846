<div id="galleryRow" class="grid-container">
    <div class="galleryPics" id="galleryPictures">
        <gallerypicture 
            *ngFor="let drawing of gallery" 
            [galleryPanelType]="galleryPanelType" 
            [drawing]="drawing"
            (deleteDrawing)="deleteDrawing(drawing.image)"
        >
        </gallerypicture>
    </div>
</div>