<div class="colorpicker">
    <div *ngIf="!isColorsSpecified">
        <div class="color-row-descriptor">
            Previously Used
        </div>
        <div class="color-row" style="border: 1px solid black">
            <div class="color-square" *ngFor="let color of pastColors" [ngStyle]="{'backgroundColor': color}" (click)="pickColor(color)">
            </div>
        </div>

        <div class="color-row-descriptor">
            Grayscale
        </div>
        <div class="color-row">
            <div [ngClass]="getClasses(color)" *ngFor="let color of grayScaleColors[0]" [ngStyle]="{'backgroundColor': color}" (click)="pickColor(color)">
            </div>
        </div>
        <div class="color-row">
            <div [ngClass]="getClasses(color)" *ngFor="let color of grayScaleColors[1]" [ngStyle]="{'backgroundColor': color}" (click)="pickColor(color)">
            </div>
        </div>

        <div class="color-row-descriptor">
            Rainbow
        </div>
        <div class="color-grid">
            <div class="color-row" *ngFor="let colorRow of rainbowColors">
                <div *ngFor="let color of colorRow" [ngStyle]="{'backgroundColor': color}" [ngClass]="getClasses(color)" (click)="pickColor(color)">
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isColorsSpecified">
        <!-- Add later functionality to only include specific colors -->
    </div>
</div>