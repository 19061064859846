<div class="footer">
    <svg viewBox="0 0 1440 320" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="#000000" fill-opacity="1" d="M0,192L80,176C160,160,320,128,480,144C640,160,800,224,960,213.3C1120,203,1280,117,1360,74.7L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
  </svg>
    <div class='footer-wrapper'>
        <div class="footer-inner">
            <div class="footer-links">
                <div class="footer-section">
                    <a [routerLink]="['/home']">
                        <div class="footer-logo">
                            <scrawlbrawl-logo-horizontal strokeColor="rgb(30, 30, 30)"></scrawlbrawl-logo-horizontal>
                        </div>
                    </a>
                    <div class="socials">
                        <a href={{socialLinks.twitter}} target="_blank" title="Follow us on Twitter">
                            <twitter-icon></twitter-icon>
                        </a>
                        <a href={{socialLinks.twitch}} target="_blank" title="Follow us on Twitch">
                            <twitch-icon></twitch-icon>
                        </a>
                        <a href={{socialLinks.youtube}} target="_blank" title="Follow us on YouTube">
                            <youtube-icon></youtube-icon>
                        </a>
                        <a href={{socialLinks.discord}} target="_blank" title="Connect with us on Discord">
                            <discord-icon></discord-icon>
                        </a>
                        <a href={{socialLinks.reddit}} target="_blank" title="Connect with us on Reddit">
                            <reddit-icon></reddit-icon>
                        </a>
                    </div>
                </div>
                <div class="footer-section" *ngFor="let section of sectionsOrder">
                    <div class="footer-section-title">
                        {{section}}
                    </div>
                    <div class="footer-section-links">
                        <div class="footer-link" *ngFor="let link of sections[section]">
                            <ng-container *ngIf="link.external; else internal">
                                <a [href]="link.url" target="_blank">{{link.name}}</a>
                            </ng-container>
                            <ng-template #internal>
                                <a [routerLink]=[link.url]>{{link.name}}</a>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>